import styled from "styled-components";

export const NoDataContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

export const NoDataText = styled.span`
    font-weight: bold;
    font-size: xx-large;
    color: dimgrey;
    display: flex;
    justify-content: center;
`;
