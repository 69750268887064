import * as S from './styles';
import {useLocation, useNavigate} from "react-router-dom";
import {useEffect, useRef, useState} from "react";
import {useHotkeys} from "react-hotkeys-hook";
import ReactPlayer from "react-player";
import {DurationDisplay} from "../ToolsComponent/DurationDisplay";
import {MediaFluxInterface, MediaMetadataInterface} from "../../utils/interfaces";
import {useTranslation} from "react-i18next";
import screenfull from "screenfull";
import {OnProgressProps} from "react-player/base";
import {Loading} from "../ToolsComponent/Loading";
import {requestMediaMetadata} from "../../utils/requests";
import logoDark from "../../assets/logo-dark.svg";
import {SettingsPopup, SettingsPopupOnApply} from "../SettingsPopup";

const playerConfig = {
    file: {
        forceDASH: true,
        dashVersion: "4.7.4",
    }
}

export function Player() {

    const location = useLocation();
    const navigate = useNavigate();
    const [redirectUrl, setRedirectUrl] = useState<string | null>(null);

    const { t }: { t: any } = useTranslation();

    const [loading, setLoading] = useState<boolean>(true);
    const [mediaFlux, setMediaFlux] = useState<MediaFluxInterface | null>(null);
    const [mediaSrc, setMediaSrc] = useState<string>("");
    const [backdropSrc, setBackdropSrc] = useState<string | null>(null);
    const [mediaMetadata, setMediaMetadata] = useState<MediaMetadataInterface | null>(null);
    const [displaySettings, setDisplaySettings] = useState<boolean>(false);
    const [displayInfos, setDisplayInfos] = useState<boolean>(false);

    const [isMouseMove, setIsMouseMove] = useState<boolean>(true); //true for dev else false
    const [playerState, setPlayerState] = useState<boolean>(false);
    const [played, setPlayed] = useState<number>(0);
    const [seeking, setSeeking] = useState<boolean>(false);
    const [mediaDuration, setMediaDuration] = useState<number>(0);
    const [playedDuration, setPlayedDuration] = useState<number>(0);
    const [screenState, setScreenState] = useState<boolean>(false);
    const [volume, setVolume] = useState<number>(1);

    const playerRef = useRef<ReactPlayer>(null);

    useEffect(() => {
        window.scrollTo(0, 0);
        let state = location.state as { data: MediaFluxInterface , from: string }
        if (state.data) {
            setMediaFlux(state.data);
        }
        if (state.from) {
            setRedirectUrl(state.from);
        }
    }, [location]);

    useEffect(() => {
        // @ts-ignore
        if (mediaFlux) {
            setMediaSrc(mediaFlux?.streaming);
            setPlayerState(true);
            setMediaMetadata(null);
        }
    }, [mediaFlux]);

    useEffect(() => {
        setScreenState(screenfull.isFullscreen);
    }, [screenfull.isFullscreen , screenfull.isEnabled]);

    useEffect(() => {
        if (screenState) {
            // @ts-ignore
            screenfull.request(document.querySelector(".player-container"));
        }
        else {
            screenfull.exit();
        }
    }, [screenState]);

    useEffect(() => {
        if (isMouseMove) {
            new Promise(resolve => setTimeout(resolve, 5000)).finally(() => setIsMouseMove(false));
        }
    }, [isMouseMove]);

    useHotkeys(' ', () => {
        setPlayerState(state => !state);
    });

    useHotkeys('esc', () => {
        back()
    })

    const back = () => {
        // @ts-ignore
        navigate(redirectUrl);
    }

    const close = () => {
        navigate("/");
    }

    const stop = () => {
        setPlayerState(false);
        setMediaSrc("");
        // @ts-ignore
        new Promise(resolve => setTimeout(resolve, 2000)).finally(() => setMediaSrc(mediaFlux?.streaming));
        setLoading(true);
    }

    const changeScreenState = () => {
        setScreenState(value => !value);
    }

    const openSettings = () => {
        setDisplaySettings(true);
    }

    const closeSettingsCancel = () => {
        setDisplaySettings(false);
    }

    const closeSettingsApply = (e: SettingsPopupOnApply) => {
        // @ts-ignore
        const newLink = mediaMetadata?.link.replace("{audio}", e.language).replace("{subtitles}", e.subtitle).replace("{audioCodec}",e.audio).replace("{quality}", e.video).replace("{format}","mpd"); //mpd
        if (newLink !== mediaSrc) {
            // @ts-ignore
            setMediaSrc(newLink);
            setLoading(true);
        }
        setDisplaySettings(false);
    }

    const onBuffer =  () => {
        if (!mediaMetadata) {
            // @ts-ignore
            requestMediaMetadata(mediaFlux?.unrestrict_id).then(mediaMetadata => {
                if (mediaMetadata.backdrop_link !== "" && mediaMetadata.backdrop_link !== undefined) {
                    setBackdropSrc(mediaMetadata.backdrop_link);
                    console.log(backdropSrc);
                }
                setMediaMetadata(mediaMetadata);
            });
        }
    }

    const onReady = () => {
        setLoading(false);
    }

    const handleSeekMouseDown = () => {
        setSeeking(true);
    }

    const handleSeekChange = (e: any) => {
        setPlayed(parseFloat(e.target.value));
    }

    const handleSeekMouseUp = (e: any) => {
        setSeeking(false);
        playerRef.current?.seekTo(parseFloat(e.target.value))
    }

    const handleProgress = (state: OnProgressProps) => {
        if (!seeking) {
            setPlayed(state.played);
            setPlayedDuration(state.playedSeconds);
        }
    }

    const handleDuration = (duration: number) => {
        setMediaDuration(duration);
    }

    // @ts-ignore
    return <S.ParentContainer isMouseMove={isMouseMove} className="player-container" onMouseMove={() => setIsMouseMove(true)}>
        <ReactPlayer ref={playerRef} width="100%" height="100%" url={mediaSrc} config={playerConfig} volume={volume} playing={playerState} onDuration={handleDuration} onProgress={handleProgress} onBuffer={onBuffer} onReady={onReady} onEnded={back} >
        </ReactPlayer>
        { loading && backdropSrc !== null ? <S.Backdrop src={backdropSrc}></S.Backdrop> : <></> }
        { loading ? <Loading></Loading> : <></> }
        { displaySettings ? <SettingsPopup mediaMetadata={mediaMetadata} url={mediaSrc} onCancel={closeSettingsCancel} onApply={closeSettingsApply} /> : <></> }
        {
            displayInfos ? <S.InfosPopup>
                <S.InfosText>{t("streamingLink") + " : " + mediaSrc}</S.InfosText>
                <S.InfosText>{t("downloadLink") + " : " + mediaFlux?.download}</S.InfosText>
            </S.InfosPopup> : <></>
        }
        <S.PlayPauseButton visible={isMouseMove} onClick={() => setPlayerState(value => !value)}>
            {
                playerState ? <S.PlayPauseButtonPauseIcon/> : <S.PlayPauseButtonPlayIcon/>
            }
        </S.PlayPauseButton>
        <S.BarreTop visible={isMouseMove}>
            { redirectUrl !== null ?
            <S.ButtonContainer title={t("goBack")} onClick={back}>
                <S.BackIcon/>
            </S.ButtonContainer> : <> </>
            }
            <S.CenterContainer>
                <S.Logo src={logoDark} onClick={() => {}}/>
            </S.CenterContainer>
            <S.RightButtonContainer>
            { mediaMetadata ?
                <S.ButtonContainer title={t("getInfo")} onClick={ () => setDisplayInfos(data => !data)}>
                    <S.InfoIcon/>
                </S.ButtonContainer> : <></>
                }
                <S.ButtonContainer title={t("goHome")} onClick={close}>
                    <S.CloseIcon/>
                </S.ButtonContainer>
            </S.RightButtonContainer>
        </S.BarreTop>
        <S.BarreBottom visible={isMouseMove}>
            <S.LeftButtonContainer>
                <S.ButtonContainer onClick={() => setPlayerState(value => !value)}>
                    {
                        playerState ? <S.PauseIcon/> : <S.PlayIcon/>
                    }
                </S.ButtonContainer>
                <S.ButtonContainer onClick={stop}>
                    <S.StopIcon/>
                </S.ButtonContainer>
            </S.LeftButtonContainer>
            <S.CenterContainer>
                <DurationDisplay duration={playedDuration}/>
                <S.SeekBarre type='range' min={0} max={0.999999} step='any' value={played} onMouseDown={handleSeekMouseDown} onChange={handleSeekChange} onMouseUp={handleSeekMouseUp}></S.SeekBarre>
                <DurationDisplay duration={mediaDuration}/>
            </S.CenterContainer>
            <S.RightButtonContainer>
                <S.ButtonContainer onClick={changeScreenState}>
                    {
                        screenState ? <S.FullscreenExitIcon/> : <S.FullscreenIcon/>
                    }
                </S.ButtonContainer>
                { mediaMetadata && !displaySettings ? <S.ButtonContainer title={t("settings")} onClick={openSettings}>
                    <S.SettingsIcon/>
                </S.ButtonContainer> : <></> }
            </S.RightButtonContainer>
        </S.BarreBottom>
    </S.ParentContainer>

}