import {api, server} from "../../services/api";
import { LanguageSwitch } from "../constants";
import {MediaFluxInterface, MediaMetadataInterface, TorrentResultInterface } from "../interfaces";
import {store} from "../../store";

const apiKey = process.env.REACT_APP_API_KEY;

export const fetchSeasonDetails = (
  id: string | undefined,
  number: string | undefined
) =>
  api.get(
    `tv/${id}/season/${number}?api_key=${apiKey}&language=${LanguageSwitch()}`
  );

export const fetchEpisodeDetails = (
  id: string | undefined,
  number: string | undefined,
  episodeNumber: string | undefined
) =>
  api.get(
    `tv/${id}/season/${number}/episode/${episodeNumber}?api_key=${apiKey}&language=${LanguageSwitch()}`
  );

export const fetchShowDetails = (id: string | undefined) =>
  api.get(`tv/${id}?api_key=${apiKey}&language=en-US`);

export const fetchEpisodeCredits = (
  id: string | undefined,
  number: string | undefined,
  episodeNumber: string | undefined
) =>
  api.get(
    `tv/${id}/season/${number}/episode/${episodeNumber}/credits?api_key=${apiKey}&language=en-US`
  );

export const fetchCompanyDetails = (
  type: "network" | "company",
  id: string | undefined
) => api.get(`${type}/${id}?api_key=${apiKey}`);

export const fetchActorDetails = (id: string | undefined) =>
  api.get(`person/${id}?api_key=${apiKey}&language=${LanguageSwitch()}`);

export const fetchActorCredits = (id: string | undefined) =>
  api.get(`person/${id}/combined_credits?api_key=${apiKey}&language=en-US`);

export const fetchMediaDetails = (
  mediaType: "tv" | "movie",
  id: string | undefined
) =>
  api.get(`${mediaType}/${id}?api_key=${apiKey}&language=${LanguageSwitch()}`);

export const fetchMediaVideos = (
  mediaType: "tv" | "movie",
  id: string | undefined
) =>
  api.get(
    `${mediaType}/${id}/videos?api_key=${apiKey}&language=${LanguageSwitch()}`
  );

export const fetchMediaProviders = (
  mediaType: "tv" | "movie",
  id: string | undefined
) =>
  api.get(
    `${mediaType}/${id}/watch/providers?api_key=${apiKey}&language=${LanguageSwitch()}`
  );

export const fetchMediaCredits = (
  mediaType: "tv" | "movie",
  id: string | undefined
) => api.get(`${mediaType}/${id}/credits?api_key=${apiKey}&language=en-US`);

export const fetchTVAggregateCredits = (
  mediaType: "tv" | "movie",
  id: string | undefined
) =>
  api.get(
    `${mediaType}/${id}/aggregate_credits?api_key=${apiKey}&language=en-US`
  );

export async function userLogin(payload: any) {
  const { data: response } = await server.post("/login", payload);
  console.log(response);
  return response;
}

export async function userSignUp(payload: any) {
  const { data: response } = await server.post("/subscribe", payload);
  return response;
}

export async function updateProfileImage( data: Uint8Array) {

  // @ts-ignore
  const token = store.getState().user.token;

  const { data: response } = await server.post(`/update_profile_image`, data, {
    headers: {
      'Content-Type': 'application/octet-stream',
      authorization: `Bearer ${token}`
    }
  });
  return response;
}

export async function fetchTorrents(name: string, is_serie: boolean, saison: number | null, episode: number| null) : Promise<TorrentResultInterface[]> {

  // @ts-ignore
  const token = store.getState().user.token;

  const name_correct = name.replace("&","%26");

  let url: string = `/find_torrent/yggtorrent?name=${name_correct}&is_serie=${is_serie}`
  if(is_serie) {
    url += `&saison=${saison}&episode=${episode}`;
  }

  const { data: response } = await server.get(url, { headers: { authorization: `Bearer ${token}` } });
  return response;
}

export async function requestMediaFlux(name: string, key: string, is_serie: boolean, saison: number | null, episode: number| null) : Promise<MediaFluxInterface> {
  // @ts-ignore
  const token = store.getState().user.token;

  const name_correct = name.replace("&","%26");

  let url: string = `/media_flux?name=${name_correct}&key=${key}&is_serie=${is_serie}`
  if(is_serie) {
    url += `&saison=${saison}&episode=${episode}`;
  }

  const { data: response } = await server.get(url, { headers: { authorization: `Bearer ${token}` } });
  return response;

}

export async function requestMediaMetadata(unrestrict_id: string): Promise<MediaMetadataInterface> {
    let url: string = `/media_infos?unrestrict_id=${unrestrict_id}`;
    const { data: response } = await server.get(url);
    return response;
}
