import {useDispatch, useSelector} from "react-redux";
import { RootState } from "../../store";
import DefaultPicture from "../../assets/default-user-image.png";
import * as S from "./styles";
import {useTranslation} from "react-i18next";
import {useEffect, useState} from "react";
import {useDropzone} from "react-dropzone";
import {updateProfileImage, userLogin} from "../../utils/requests";
import {useMutation} from "react-query";
import {setUser} from "../../store/User.store";
import toast from "react-hot-toast";
import {useNavigate} from "react-router-dom";

export function UserProfile() {
  const profileImage = useSelector(
    (state: RootState) => state.user.profileImage
  );
  const firstName = useSelector((state: RootState) => state.user.firstName);
  const lastName = useSelector((state: RootState) => state.user.lastName);
  const currentDate = new Date();
  const birthday = useSelector((state: RootState) => state.user.birthday);
  const isUserLogged = useSelector((state: RootState) => state.user.isLogged);
  const accountCreatedAt = useSelector(
    (state: RootState) => state.user.accountCreatedAt
  );

  const { t }: { t: any } = useTranslation();
  const dispatch = useDispatch();

  const [imageSrc, setImageSrc] = useState<string | null>(null);

  useEffect(() => {
    if (profileImage) {
      // @ts-ignore
      const stringImage = String.fromCharCode.apply(null, profileImage);
      /* //En cas d'erreur -> https://medium.com/@koteswar.meesala/convert-array-buffer-to-base64-string-to-display-images-in-angular-7-4c443db242cd
      const STRING_CHAR = TYPED_ARRAY.reduce((data, byte)=> {
      return data + String.fromCharCode(byte);
      }, ''));
       */

      let base64String = btoa(stringImage);
      const imageUrl = `data:image/png;base64,${base64String}`;
      setImageSrc(imageUrl);

    }
  }, [profileImage]);

  const birthdayDate = new Date(birthday !== null ? birthday : "1969-02-02");

  const userAge =
    isUserLogged &&
    Math.abs(currentDate.getTime() - birthdayDate.getTime()) / 3.154e10;

  const accountCreationDate = new Date(
    accountCreatedAt !== null ? accountCreatedAt : "1969-01-01"
  );

  const {getRootProps, getInputProps, open} = useDropzone({
    maxFiles:1,
    accept: {
      'image/png': [".png"],
      'image/jpeg': [".jpeg"],
      'image/jpg': [".jpg"],
      'image/gif': [".gif"],
      'image/*': []
    },
    onDrop: acceptedFiles => {
      console.log("acceptedFiles", acceptedFiles);
      // @ts-ignore
      acceptedFiles[0].arrayBuffer().then((arrayBuffer) => {
        const temp = new Uint8Array(arrayBuffer);
        // @ts-ignore
        updateProfileImage(temp).then((data) => dispatch(setUser(data)))
      })
    }
  });

  return (
    <S.Container>
      <S.Content>
        <S.UserNameAndPictureContainer {...getRootProps({className: 'dropzone'})}>
          <S.UserPictureContainer onDoubleClick={open}>
            <input {...getInputProps()}></input>
            <S.UserPicture src={ imageSrc || DefaultPicture }>
            </S.UserPicture>
            <S.InfoText1>{t("changeProfileImage1")}</S.InfoText1>
            <S.InfoText2>{t("changeProfileImage2")}</S.InfoText2>
          </S.UserPictureContainer>
          <S.UserName>{`${firstName} ${lastName}`}</S.UserName>
        </S.UserNameAndPictureContainer>
        <S.UserDatesContainer>
          <S.UserAge>{`${Number(userAge).toFixed(0)} ${t("yearsOld")}`}</S.UserAge>
          <S.AccountBirthday>
            {`${t("createdIn")} ${accountCreationDate.getDate()}/${
              accountCreationDate.getMonth() + 1
            }/${accountCreationDate.getFullYear()}`}
          </S.AccountBirthday>
        </S.UserDatesContainer>
        <S.UserDescriptionContainer>
          <S.UserDescription>...</S.UserDescription>
        </S.UserDescriptionContainer>
      </S.Content>
    </S.Container>
  );
}
