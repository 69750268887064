import styled from "styled-components";

export const Popup = styled.div`
    position: fixed;
    padding: 2%;
    z-index: 10;
    background-color: rgb(34, 30, 34);
    box-shadow: rgba(0, 0, 0, 0.84) 4px 77px 132px 13px inset;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 40%;
    width: 30%;
    border-radius: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
`;

export const Title = styled.h1`
    color: white;
`;

export const SepareBar = styled.div`
    color: white;
    height: 0;
    width: 80%;
    border: 2px solid white;
    margin-bottom: 5%;
`;

export const SettingContainer  = styled.div`
    display: flex;
    flex-direction: row;
    width: 60%;
    justify-content: space-around;
    align-items: center;
    background-color: black;
    border-radius: 2rem;
    border: 1px solid white;
    margin: 1%;
    padding: 1%;
`;

export const SettingLabel = styled.h2`
    color: white;
    font-size: 1.2rem;
    margin: 0;
`;

export const SettingSelector = styled.select`
    color: white;
    margin-left: 2%;
    margin-right: 2%;
    border: 0;
    background-color: black;
`;

export const SettingOption = styled.option`
    color: white;
`;

export const ButtonsContainer = styled.div`
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-around;
`;

export const ButtonContainer = styled.div`
    display: flex;
    background-color: black;
    justify-content: center;
    align-items: center;
    transition: height 2s, width 2s, background-color 2s;
    padding: 2%;
    margin-top: 5%;
    border-radius: 2rem;

    &:hover {
        background-color: dimgrey;
        padding: 2.2%;
    }
`;

export const ButtonLabel = styled.span`
    color: white;
    font-size: 1.2rem;
`;