import * as S from "./styles";
import {useTranslation} from "react-i18next";

export function Footer() {

  const { t }: { t: any } = useTranslation();

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <S.Container>
      <S.Content>
        <S.FooterText>&copy; 2024</S.FooterText>
        <S.FooterText>TomFlix Inc.</S.FooterText>
        <S.BackToTopButton
          title={t("backTop")}
          onClick={() => scrollToTop()}
        />
      </S.Content>
    </S.Container>
  );
}
