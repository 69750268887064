import styled from "styled-components";
import {IoIosClose} from "react-icons/io";

export const Popup = styled.div`
    position: fixed;
    padding: 4rem 3rem;
    z-index: 10;
    background-color: rgb(34, 30, 34);
    box-shadow: rgba(0, 0, 0, 0.84) 4px 77px 132px 13px inset;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 90%;
    width: 90%;
    border-radius: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

export const CloseButton = styled.span`
    background-color: black;
    height: 2.7rem;
    width: 2.7rem;
    border-radius: 50%;
    top: 0.5rem;
    right: 0.5rem;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: height 2s, width 2s, background-color 2s;
    
    &:hover {
        background-color: dimgrey;
        height: 2.9rem;
        width: 2.9rem;
    }
    
`;

export const CloseIcon = styled(IoIosClose)`
    color: white;
    height: 2.5rem;
    width: 2.5rem;
`;

export const TableContainer = styled.div`
    flex-grow: 1;
    overflow: auto;
    width: 100%;
`;

export const TableTorrent = styled.table`
    display: table;
    border-collapse: collapse;
    border: 2px solid white;
    font-family: sans-serif;
    letter-spacing: 1px;
    box-sizing: border-box;
    color: white;
    font-size: 1.2rem;
    text-align: center;
    width: 100%;
    flex-grow: 1;
`;

export const TheadTorrent = styled.thead`
`;

export const ThTorrent = styled.th`
    border-collapse: collapse;
    border: 2px solid white;
    padding: 0 10px;
`;

export const TbodyTorrent = styled.tbody`
`;

export const TrTorrent = styled.tr`
    border-collapse: collapse;
    border: 2px solid white;
    color: white;
    font-family: sans-serif;
    letter-spacing: 1px;
    font-size: 1.2rem;
    text-align: center;
`;

export const TdTorrent = styled.td`
    border-collapse: collapse;
    border: 2px solid white;
`;

export const PlayTorrent = styled.button<{ exist: boolean}>`
    padding: 2px 10px;
    margin: 2px 10px;
    border: none;
    border-radius: 5px;
    transition: background-color 2s;
    color: ${p => (p.exist ? "green" : "red")};
    
    &:hover {
        background-color: transparent;
    }
    
`;