import * as S from "./styles";
import {useNavigate} from "react-router-dom";

export function DetailsReturnArrow() {

  const navigate = useNavigate();

  return (
    <S.ArrowBackContainer onClick={() => {
      navigate(window.location.pathname.substring(0, (window.location.pathname.lastIndexOf('/', window.location.pathname.lastIndexOf('/') - 1)) + 1));
    }}>
      <S.WhiteArrowBack />
    </S.ArrowBackContainer>
  );
}
