import styled from "styled-components";
import { FaSpinner } from "react-icons/fa";

export const LoadingContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40%;
    height: 40%;

    /* position the div in center */
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    
`;

export const LoadingIcon = styled(FaSpinner)` 
    width: 80%;
    height: 80%;
    animation: rotating 2s linear infinite;
    color: dimgrey;
    
    @keyframes rotating {
        from {
            transform: rotate(0deg);
        }
        to {
            transform: rotate(360deg);
        }
    }
    
`;