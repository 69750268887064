import * as S from './styles';
import {useTranslation} from "react-i18next";
import {MediaMetadataInterface} from "../../utils/interfaces";
import {useEffect, useState} from "react";

export interface SettingsPopupOnApply {
    language: string,
    audio: string,
    video: string,
    subtitle: string,
}

interface SettingsPopupProps {
    mediaMetadata: MediaMetadataInterface | null,
    url: string | null,
    onCancel: () => void,
    onApply: (data: SettingsPopupOnApply) => void,
}

export function SettingsPopup(props: SettingsPopupProps) {

    const [language, setLanguage] = useState<string>("");
    const [audio, setAudio] = useState<string>("");
    const [video, setVideo] = useState<string>("");
    const [subtitle, setSubtitle] = useState<string>("");

    const {t}: { t: any } = useTranslation();

    useEffect(() => {
        // @ts-ignore
        setLanguage(props.url.split("/")[5]);
        // @ts-ignore
        setAudio(props.url.split("/")[7]);
        // @ts-ignore
        setVideo(props.url.split("/")[8].split(".")[0]);
        // @ts-ignore
        setSubtitle(props.url.split("/")[6]);
    }, [props.url]);

    const handleChangeLanguages = (e: any) => {
        setLanguage(e.target.value.split("$")[0]);
        setAudio(e.target.value.split("$")[1]);
    }

    const handleChangeQualities = (e: any) => {
        setVideo(e.target.value);
    }

    const handleChangeSubtitles = (e: any) => {
        setSubtitle(e.target.value);
    }

    return <S.Popup>
        <S.Title>Media {t("settings")}</S.Title>
        <S.SepareBar></S.SepareBar>
        <S.SettingContainer>
            <S.SettingLabel>{t("languages")}: </S.SettingLabel>
            <S.SettingSelector value={`${language}$${audio}`} onChange={handleChangeLanguages}>
                {
                    // @ts-ignore
                    Object.entries(props.mediaMetadata?.audios).map(([key, value]) => (
                        <S.SettingOption value={`${key}$${value[1]}`}>{value[0]} {value[1]}</S.SettingOption>
                    ))
                }
            </S.SettingSelector>
        </S.SettingContainer>
        <S.SettingContainer>
            <S.SettingLabel>{t("qualities")}: </S.SettingLabel>
            <S.SettingSelector value={video} onChange={handleChangeQualities}>
                {
                    // @ts-ignore
                    Object.entries(props.mediaMetadata?.qualities).map(([key, value]) => (
                        <S.SettingOption value={key}>{value}</S.SettingOption>
                    ))
                }
            </S.SettingSelector>
        </S.SettingContainer>
        <S.SettingContainer>
            <S.SettingLabel>{t("subtitles")}: </S.SettingLabel>
            <S.SettingSelector value={subtitle} onChange={handleChangeSubtitles}>
                <S.SettingOption value="none">{t("none")}</S.SettingOption>
                {
                    // @ts-ignore
                    Object.entries(props.mediaMetadata?.subtitles).map(([key, value]) => (
                        <S.SettingOption value={key}>{value}</S.SettingOption>
                    ))
                }
            </S.SettingSelector>
        </S.SettingContainer>
        <S.ButtonsContainer>
            <S.ButtonContainer onClick={props.onCancel}>
                <S.ButtonLabel>{t("btnCancel")}</S.ButtonLabel>
            </S.ButtonContainer>
            <S.ButtonContainer onClick={() => props.onApply({ language, audio, video, subtitle})}>
                <S.ButtonLabel>{t("btnApply")}</S.ButtonLabel>
            </S.ButtonContainer>
        </S.ButtonsContainer>
    </S.Popup>
}