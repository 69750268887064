import { useSelector } from "react-redux";
import { RootState } from "../../store/";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { logout } from "../../store/User.store";
import DefaultUserImage from "../../assets/default-user-image.png";
import * as S from "./styles";
import { useTranslation } from "react-i18next";
import {useEffect, useState} from "react";

export function UserPopover() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t }: { t: any } = useTranslation();

  const profileImage = useSelector(
    (state: RootState) => state.user.profileImage
  );

  const firstName = useSelector((state: RootState) => state.user.firstName);

  const lastName = useSelector((state: RootState) => state.user.lastName);

  const [imageSrc, setImageSrc] = useState<string | null>(null);

  useEffect(() => {
    if (profileImage) {
      // @ts-ignore
      const stringImage = String.fromCharCode.apply(null, profileImage);
      /* //En cas d'erreur -> https://medium.com/@koteswar.meesala/convert-array-buffer-to-base64-string-to-display-images-in-angular-7-4c443db242cd
      const STRING_CHAR = TYPED_ARRAY.reduce((data, byte)=> {
      return data + String.fromCharCode(byte);
      }, ''));
       */

      let base64String = btoa(stringImage);
      const imageUrl = `data:image/png;base64,${base64String}`;
      setImageSrc(imageUrl);

    }
  }, [profileImage]);

  const handleClick = () => {
    dispatch(logout());
    navigate("/login");
  };

  return (
    <S.Container>
      <OverlayTrigger
        trigger="click"
        placement="bottom"
        overlay={
          <Popover>
            <Popover.Body>
              <S.UserContainer onClick={() => navigate("/profile")}>
                <S.UserPictureContainer>
                  <S.UserPicture src={imageSrc || DefaultUserImage} />
                </S.UserPictureContainer>
                <S.UserNameContainer>
                  <S.UserName>{`${firstName} ${lastName}`}</S.UserName>
                  <S.ProfileLink>{t("goToProfile")}</S.ProfileLink>
                </S.UserNameContainer>
              </S.UserContainer>
              <S.UnorganizedList>
                <S.ListItem>
                  <S.MenuLink onClick={() => handleClick()}>
                    {t("logout")}
                  </S.MenuLink>
                </S.ListItem>
              </S.UnorganizedList>
            </Popover.Body>
          </Popover>
        }
      >
        <S.ProfileContainer src={imageSrc || DefaultUserImage} />
      </OverlayTrigger>
    </S.Container>
  );
}
