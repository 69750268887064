import * as S from './styles';
import {useEffect, useState} from "react";

interface DurationDisplayProps {
    duration: number;
}

export function DurationDisplay({ duration } : DurationDisplayProps) {

    const [seconds, setSeconds] = useState<string>("00");
    const [minutes, setMinutes] = useState<string>("00");
    const [hours, setHours] = useState<string>("00");

    useEffect(() => {
        const hours = Math.floor(duration / 3600).toString().padStart(2, '0');
        const minutes = Math.floor((duration % 3600) / 60).toString().padStart(2, '0');
        const seconds = Math.floor((duration % 60)).toString().padStart(2, '0');
        setSeconds(seconds);
        setMinutes(minutes);
        setHours(hours);
    }, [duration]);

    return <S.Container>
        <S.Text>{hours}:{minutes}:{seconds}</S.Text>
    </S.Container>;

}