import * as S from "./styles";
import {useTranslation} from "react-i18next";

export function NoData() {

    const { t }: { t: any } = useTranslation();

    return <S.NoDataContainer>
        <S.NoDataText>
            { t("noData") }
        </S.NoDataText>
    </S.NoDataContainer>
}