import { EpisodeDetails } from "../components/Details/EpisodeDetails";
import { PageWrapper } from "./pageWrapper";
import {Outlet} from "react-router-dom";

export function EpisodeDetailsPage() {
  return <>
    <PageWrapper children={<EpisodeDetails />} />
    <Outlet />
  </>;
}
