import {useTranslation} from "react-i18next";
import * as S from "./styles";
import {useEffect, useState} from "react";
import {
    MediaDetailsInterface, MediaFluxInterface,
    TorrentResultInterface,
} from "../../utils/interfaces";
import i18n from "../../i18n";
import {
    fetchMediaDetails,
    fetchTorrents, requestMediaFlux
} from "../../utils/requests";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {NoData} from "../ToolsComponent/NoData";
import {Loading} from "../ToolsComponent/Loading";

interface TorrentDetailsProps {
    mediaType: "tv" | "movie";
}


export function TorrentDetails({ mediaType }: TorrentDetailsProps) {
    const { id } = useParams();
    const { seasonNumber } = useParams();
    const { episodeNumber } = useParams();
    const { t }: { t: any } = useTranslation();
    const [mediaDetails, setMediaDetails] = useState<MediaDetailsInterface>();
    const [torrents, setTorrents] = useState<TorrentResultInterface[]>([]);
    const [mediaFlux, setMediaFlux] = useState<MediaFluxInterface | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [doesMediaExist, setDoesMediaExist] = useState<boolean>(false);
    const [haveSeason, setHaveSeason] = useState<boolean>(false);
    const [haveEpisode, setHaveEpisode] = useState<boolean>(false);

    const navigate = useNavigate();
    const location = useLocation();
    const path = location.pathname;

    const fetchData = () => {
        Promise.all([
            fetchMediaDetails(mediaType, id),
        ])
            .then((response) => {
                setMediaDetails(response[0].data);
            })
            .catch((error) => {
                setDoesMediaExist(false);
            });
    };

    const fetchMediaFlux = (key: string) => {
        if (mediaType === "movie") {
            // @ts-ignore
            requestMediaFlux(mediaDetails?.title, key, false, null ,null).then((response) => {
                setMediaFlux(response);
            });
        }
        else {
            //@ts-ignore
            requestMediaFlux(mediaDetails?.name, key,true, Number(seasonNumber), Number(episodeNumber)).then((response) => {
                setMediaFlux(response);
            });
        }
    };

    useEffect(() => {
        window.scrollTo(0, 0);
        setLoading(true);
        fetchData();
    }, [i18n.language, id, seasonNumber, episodeNumber]);

    useEffect(() => {
        if (mediaDetails) {
            if (mediaType === "movie") {
                // @ts-ignore
                fetchTorrents(mediaDetails?.title, false, null, null).then(response => {
                    setTorrents(response);
                }).then(() => {
                        // @ts-ignore
                        setTorrents(data => data.filter(value => value.year === null || value.year === Number(mediaDetails.release_date.split("-")[0])));
                        setTorrents(data => data.filter(value => value.seeders !== 0));
                    }
                ).then(() => setLoading(false)).catch((error) => {
                    console.log(error);
                });

            } else {
                // @ts-ignore
                fetchTorrents(mediaDetails?.name, true, Number(seasonNumber), Number(episodeNumber)).then(response => {
                    setTorrents(response);
                }).then( () => {
                    setTorrents(data => data.filter(value => value.seeders !== 0));
                })
                .then( () => {
                    setHaveSeason(torrents.some(value => value.season !== null && value.season !== undefined));
                    setHaveEpisode(torrents.some(value => value.episode !== null && value.episode !== undefined));
                }).then(() => setLoading(false)).catch((error) => {
                    console.log(error);
                });
            }
        }
    }, [mediaDetails]);

    useEffect(() => {
        if (torrents.length !== 0) {
            setDoesMediaExist(true);
        }
        else {
            setDoesMediaExist(false);
        }
    }, [torrents]);

    useEffect(() => {
        if (mediaFlux) {
            navigate("/player", { state: { data: mediaFlux, from: location.pathname }})
        }
    }, [mediaFlux]);

    return (

        <S.Popup>
            <S.CloseButton onClick={ () => navigate(path.replace("/torrents", "")) } title={ t("closeButton") }>
                <S.CloseIcon />
            </S.CloseButton>
            {
                doesMediaExist && !loading ?
                    <S.TableContainer>
                        <S.TableTorrent>
                            <S.TheadTorrent>
                                <S.ThTorrent>{t("nameTorrent")}</S.ThTorrent>
                                <S.ThTorrent>{t("sizeTorrent")}</S.ThTorrent>
                                <S.ThTorrent>{t("seederTorrent")}</S.ThTorrent>
                                <S.ThTorrent>{t("leecherTorrent")}</S.ThTorrent>
                                {
                                    haveSeason ?
                                    <S.ThTorrent>{t("seasonTorrent")}</S.ThTorrent>
                                         : <></>
                                }
                                {
                                    haveEpisode ?
                                    <S.ThTorrent>{t("episodeTorrent")}</S.ThTorrent>
                                        : <></>
                                }
                                <S.ThTorrent>{t("resolutionTorrent")}</S.ThTorrent>
                                <S.ThTorrent></S.ThTorrent>
                            </S.TheadTorrent>
                            <S.TbodyTorrent>
                            {torrents.map((item: TorrentResultInterface) => {
                                    return <S.TrTorrent key={item.name}>
                                        <S.TdTorrent><span title={item.title + " (" + item.year + ")" } >{item.name}</span></S.TdTorrent>
                                        <S.TdTorrent>{item.size}</S.TdTorrent>
                                        <S.TdTorrent>{item.seeders}</S.TdTorrent>
                                        <S.TdTorrent>{item.leechers}</S.TdTorrent>
                                        {
                                            haveSeason ?
                                                <S.TdTorrent><span title={"Episode torrent : " + item.episode_files.join(", ")}>{item.season === null ? "" : item.season}</span></S.TdTorrent>
                                                : <></>
                                        }
                                        {
                                            haveEpisode ?
                                            <S.TdTorrent>{item.episode  === null ? "" : item.episode}</S.TdTorrent>
                                                : <></>
                                        }
                                        <S.TdTorrent>{item.resolution === null ? "Nan" : item.resolution}</S.TdTorrent>
                                        <S.TdTorrent><S.PlayTorrent exist={item.rd_exist} title={ "Language : " + item.language } onClick={() => fetchMediaFlux(item.key)}>{t("playTorrent")}</S.PlayTorrent></S.TdTorrent>
                                    </S.TrTorrent>
                                })}
                            </S.TbodyTorrent>
                        </S.TableTorrent>
                    </S.TableContainer>
                 : loading ? <Loading /> : <NoData />
            }
        </S.Popup>
    );
}