import styled from "styled-components";
import {FaArrowLeft, FaCompress, FaExpand, FaStop} from "react-icons/fa";
import {IoIosClose, IoIosPause, IoIosPlay, IoIosSettings} from "react-icons/io";
import {TiInfoLarge} from "react-icons/ti";

export const ParentContainer = styled.div<{isMouseMove: boolean}>`
    position: fixed;
    display: flex;
    justify-content: space-between;
    background-color: rgb(0, 0, 0);
    flex-direction: column;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;

    cursor: ${({isMouseMove}) => (isMouseMove ? 'auto' : 'none')};
`;

export const Backdrop = styled.img`
    position: absolute;
    width: 100%;
    height: 100%;
`;

export const PlayPauseButton = styled.div<{visible: boolean}>`
    display: flex;
    background-color: black;
    justify-content: center;
    align-items: center;
    transition: height 2s, width 2s, background-color 2s;
    height: 200px;
    width: 200px;
    border-radius: 50%;
    
    /* position the div in center */
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    &:hover {
        background-color: rgba(217, 153, 24, 0.39);
        height: 220px;
        width: 220px;
    }
    visibility: ${({visible}) => (visible ? 'visible' : 'hidden')};

    & > * {
        visibility: ${({visible}) => (visible ? 'visible' : 'hidden')};
    }
`;

export const  PlayPauseButtonPlayIcon = styled(IoIosPlay)`
    color: white;
    height: 80%;
    width: 80%;
`;

export const  PlayPauseButtonPauseIcon = styled(IoIosPause)`
    color: white;
    height: 80%;
    width: 80%;
`;


export const BarreTop = styled.div<{visible: boolean}>`
    padding: 1.5rem;
    width: 100%;
    height: 8%;
    display: flex;
    justify-content: space-between;
    position: absolute;
    top: 0;
    visibility: ${({visible}) => (visible ? 'visible' : 'hidden')};
    background-color: rgb(34, 30, 34);
    box-shadow: rgba(0, 0, 0, 0.84) 4px 77px 132px 13px inset;
    align-items: center;
    flex-direction: row;
    & > * {
        visibility: ${({visible}) => (visible ? 'visible' : 'hidden')};
    }
`;

export const BarreBottom = styled.div<{visible: boolean}>`
    padding: 1.5rem;
    width: 100%;
    height: 8%;
    display: flex;
    justify-content: space-between;
    position: absolute;
    bottom: 0;
    visibility: ${({visible}) => (visible ? 'visible' : 'hidden')};
    background-color: rgb(34, 30, 34);
    box-shadow: rgba(0, 0, 0, 0.84) 4px 77px 132px 13px inset;
    align-items: center;
    flex-direction: row;
    & > * { 
        visibility: ${({ visible }) => (visible ? 'visible' : 'hidden')}; 
    }
`;

export const SeekBarre = styled.input`
    width: 100%;
    margin-left: 2%;
    margin-right: 2%;
    color: "red";
`;

export const ButtonContainer = styled.div`
    display: flex;
    background-color: black;
    justify-content: center;
    align-items: center;
    transition: height 2s, width 2s, background-color 2s;
    height: 2.7rem;
    width: 2.7rem;
    border-radius: 50%;

    &:hover {
        background-color: rgba(217, 153, 24, 0.39);
        height: 2.9rem;
        width: 2.9rem;
    }
`;

export const BackIcon = styled(FaArrowLeft)`
    color: white;
`;

export const CloseIcon = styled(IoIosClose)`
    color: white;
    height: 2.5rem;
    width: 2.5rem;
`;

export const InfoIcon = styled(TiInfoLarge)`
    color: white;
    height: 2rem;
    width: 2rem;
`;

export const PlayIcon = styled(IoIosPlay)`
    color: white;
    height: 2rem;
    width: 2rem;
`;

export const LeftButtonContainer = styled.div`
    display: flex;
    gap: 0.5rem;
`;

export const CenterContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    margin: 0 2% 0 2%;
`;

export const RightButtonContainer = styled.div`
    display: flex;
    gap: 0.5rem;
`;

export const PauseIcon = styled(IoIosPause)`
    color: white;
    height: 2rem;
    width: 2rem;
`;

export const StopIcon = styled(FaStop)`
    color: white;
`;

export const FullscreenExitIcon = styled(FaCompress)`
    color: white;
`;

export const FullscreenIcon = styled(FaExpand)`
    color: white;
    height: 1.5rem;
    width: 1.5rem;
`;

export const SettingsIcon = styled(IoIosSettings)`
    color: white;
    height: 2rem;
    width: 2rem;
`;

export const Logo = styled.img`
  width: 160px;
  cursor: pointer;
  transition: 0.4s;

  &:hover {
    transform: scale(1.05);
  }
`;

export const InfosPopup = styled.div`
    position: fixed;
    padding: 0.2%;
    z-index: 10;
    background-color: rgb(34, 30, 34);
    box-shadow: rgba(0, 0, 0, 0.84) 4px 77px 132px 13px inset;
    top: 17%;
    right: 17%;
    transform: translate(20%, -50%);
    height: 15%;
    width: 80%;
    border-radius: 2rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
`;

export const InfosText = styled.p`
    color: white;
    font-size: 1.2rem;
    margin: 1rem;
`;